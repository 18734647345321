






























import { Vue, Component, Provide } from "vue-property-decorator";
import Nav from "@/components/header.vue";
import serve from '@/serve/order'
@Component({
  components: {
    Nav,
  },
})
export default class ChooseAddress extends Vue {
  memberAd=[]
  @Provide() listData = [];
  @Provide() radio = [];
  created(){
    this.getMemberAd()
  }
    async getMemberAd(){
    const res = await serve.getMemberAd()
    if(res.data.data.length>0){
      this.memberAd = res.data.data
    }
  }
  private goTo(val: string): void {
    this.$router.push(val)
  }
  private radioChange(): void {
    // console.log(this.radio);
  }
}
